import React, { useState } from "react";
import FullStackDevelopmentImage from "../../assets/images/about/about-us/1.jpg";
import img1 from "../../assets/images/services/style7/1.png";
import img2 from "../../assets/images/services/style7/2.png";
import img3 from "../../assets/images/services/style7/3.png";
import choose from "../../assets/images/choose/3.png";
import "./FullStackDevelopment.scss"; // Import the SCSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";


export default function FullStackDevelopment() {
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();


    const handleMouseEnter = () => setAnimate(true);

    const skills = [
        { name: "Full Stack Development", percent: 92 },
        { name: "Software Development", percent: 90 },
        { name: "App Development", percent: 85 },
        { name: "Web Development", percent: 95 },
    ];

    return (
        <>
            <div className="FullStackDevelopment">
                <div className="FullStackDevelopment-heading">
                    <h1>Full Stack Development</h1>
                    <label><span onClick={() => navigate("/")} style={{ cursor: "pointer", }}>
                            Home
                        </span>{" "} /
                        <span onClick={() => navigate("/services/our-services")} style={{ cursor: "pointer", paddingLeft: "5px" }}>
                            Services
                        </span>{" "}/ Full Stack Development</label>
                </div>

                {/* First Section: Left Text and Right Image */}
                <div className="FullStackDevelopment-section">
                    <div className="FullStackDevelopment-text">
                        <p className="subtext">Full Stack Development</p>
                        <div className="animation">

                            <div className="container"></div>
                            <div className="circle"></div>
                        </div>
                        <p className="subtext2">Full Stack Development that fulfills Your Vision</p>
                        <p className="subtext3">Econsign Soft is the leading full-stack web development company which can provide many advantages for any company.</p>
                        <p className="subtext4">
                        Full stack development refers to the end-to-end application software development, including the front end and back end. The front end consists of the user interface, and the back end takes care of the business logic and application workflows. We help in choosing the right technologies for the project development and testing both on the front end and the back end. We write clean code across the stack by following the best practices of the tools used.                        </p>
                        <button onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                    <div className="FullStackDevelopment-image">
                        <img src={FullStackDevelopmentImage} alt="Software Development" />
                    </div>
                </div>

                {/* Second Section: Three Cards */}
                <div className="FullStackDevelopment-cards">
                    <div className="card">
                        <img src={img1} alt="Expert People" />
                        <div className="card-content">
                            <h3>Expert People</h3>
                            <p>We have amassed significant expertise across a wide range of industries.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src={img2} alt="Fast Growing Process" />
                        <div className="card-content">
                            <h3>Fast Growing Process</h3>
                            <p>With our expertise and dedicated approach we help in growing our client business effectively.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src={img3} alt="Creative Ideas" />
                        <div className="card-content">
                            <h3>Creative Ideas</h3>
                            <p>We have innovative and creative business ideas which help our clients to stay one step ahead.</p>
                        </div>
                    </div>
                </div><br/><br/><br/><br/>
                <div className="business-section">
                <img alt="Image 1" src={choose} className="image" />

                    <div
                        className="text-container"
                        onMouseEnter={handleMouseEnter}
                    >
                        <h6>Why Choose Us</h6>
                        <h2 style={{fontSize:"36px"}}>We Are Increasing Business Success With Technology</h2>
                        <p style={{fontSize:"18px"}}>
                            Over 8 years working in IT services developing software applications
                            and mobile apps for clients all over the world.
                        </p>

                        <div className="skills">
                            {skills.map((skill, index) => (
                                <div className="skill" key={index}>
                                    {/* <a style={{ fontWeight: "500" }}>{skill.name}</a> */}
                                    <span className="progress-percent">{`${skill.percent}%`}</span>
                                    <div className="progress-bar">
                                        <div
                                            className="progress"
                                            style={{
                                                width: animate ? `${skill.percent}%` : "0%",
                                                transition: animate ? "width 2s ease-in-out" : "none",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br />
                        <button className="learn-more-btn" onClick={() => navigate("/contact-us")}>Learn-More</button>
                    </div>
                </div>
            </div>
        </>
    );
}

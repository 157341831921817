import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./OurServices.scss"; // Import the SCSS file
import { motion } from "framer-motion";
import { FaLaptopCode, FaMobileAlt, FaGlobe, FaPalette, FaLaptop } from "react-icons/fa";

const services = [
  {
    name: "Full Stack Development",
    icon: <FaLaptopCode />,
    description: "We provide comprehensive full-stack development services, encompassing both front-end and back-end technologies, to deliver seamless and efficient applications",
    route: "/services/full-stack-development",
  },
  {
    name: "Software Development",
    icon: <FaLaptop />,
    description: "From conceptualization to deployment, we create robust and scalable software solutions tailored to meet specific business requirements.",
    route: "/services/software-development",
  },
  {
    name: "App Development",
    icon: <FaMobileAlt />,
    description: "We specialize in developing feature-rich mobile applications for iOS, Android, and cross-platform environments to help businesses connect with their customers on the go.",
    route: "/services/app-development",
  },
  {
    name: "Web Development",
    icon: <FaGlobe />,
    description: "Our expertise in web technologies allows us to build dynamic, user-friendly, and responsive websites that enhance your online presence.",
    route: "/services/web-development",
  },
  {
    name: "UI/UX Design",
    icon: <FaPalette />,
    description: "Our design team focuses on creating intuitive, aesthetically pleasing, and user-centric interfaces to deliver an exceptional user experience.",
    route: "/services/ui-ux-designs",
  },
];

const OurServices = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  return (
    <div className="services-container">
      <h1 className="title">Our Services</h1>
      <p>We offer a wide range of IT services designed to meet the evolving needs of businesses in
      diverse industries</p>
      <div className="service-list">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="service-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            onClick={() => navigate(service.route)} // Navigate on card click
            style={{ cursor: "pointer" }} // Make the card appear clickable
          >
            <div className="icon">{service.icon}</div>
            <h2 className="service-name">{service.name}</h2>
            <p className="service-description">{service.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;

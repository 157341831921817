import React from 'react';
import Banner from '../../assets/images/banner.jpg';
import './Banner.scss'; // Import the SCSS file

function BannerPage() {
    const handleClick = () => {
        // Redirect to the YouTube video when the button is clicked
        window.location.href = "https://www.youtube.com/watch?v=uqZPq6mZhSI";
    };
    return (
        <div className="banner-container">
            <div className="overlay"></div>
            <div className="content-wrapper">
                <div className="content">
                    <h1 className="heading">IT Consulting Services For Your Business</h1><br />
                    <h5 className="subheading">
                        We are a leading technology solutions provider doing business worldwide for over 10+ years.
                    </h5><br />
                    <button className="play-button" onClick={handleClick}>
                        <i className="play-icon">▶</i>
                    </button>

                </div>
                <div className="form-box-wrapper">
                    <div className="form-box">
                        <div className="top">
                            <h1>Get Quote</h1>
                            <h3 className="form-subheading">We are here to help you with our experts</h3>
                        </div>
                        <div>
                            <input className="inpl" type="text" placeholder="Name" />
                        </div>
                        <br />
                        <div>
                            <input className="inpl" type="email" placeholder="E-mail" />
                        </div>
                        <br />
                        <div>
                            <input className="inpl" type="tel" placeholder="Phone Number" />
                        </div>
                        <br />
                        <div>
                            <input className="inpl" type="text" placeholder="Message" />
                        </div>
                        <br />
                        <div>
                            <button className="submit-button">
                                <a className='buttontext'>Submit Now</a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerPage;

import React from "react";
import logo from "assets/images/eLogo.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { CiClock2, CiLocationOn } from "react-icons/ci";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";

export default function Footer() {
  return (
    <>
      <div className="footer-section">
        <div className="footer-section-1">
          <div className="footer-head">
          <Link to={"/"}>
              <img src={logo} style={{ width: "90%" }} alt="" />
            </Link>
          </div>
          <div className="footer-para">
            <p className="para1">
            
              EconSign Soft is a software development company with a full cycle
              of services. For over 10 years, we have been helping enterprises
              around the world to transform business by creating effective
              digital solutions with the use of innovative technologies.
            </p>
          </div>
          <div className="footer-icon">
            <ul className="icon-list">
            <li><Link to={"https://www.facebook.com/econsignsoft"}><FaFacebookF /></Link></li>
          <li><Link to={"https://x.com/econsignsoft"}><RiTwitterXLine /></Link></li>
          <li><Link to={"https://www.linkedin.com/company/econsignsoft/"}><FaLinkedinIn /></Link></li>
          <li><Link to={"https://www.instagram.com/_econsignsoft_/"}><FaInstagram /></Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-section-2">
          <div className="footer-head">
            <label htmlFor="">IT Services</label>
          </div>
          <div className="footer-para">
            <ul>
            <li>
                <Link to={"/services/software-development"}>Software Development
                </Link>
              </li>
              <li>
                <Link to={"/services/web-development"}>Web Development
                </Link>
              </li>
              <li>
                <Link to={"/services/full-stack-development"}>Full Stack Development
                </Link>
              </li>
              <li>
                <Link to={"/services/app-development"}>App Development
                </Link>
              </li>
              <li>
                <Link to={"/services/ui-ux-designs"}>UI/UX Design
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-section-3">
          <div className="footer-head">
            <label htmlFor="">Contact Info</label>
          </div>

          <div className="footer-para">
            <ul className="footer-contact-info">
              <li>
                <span className="foot-icon">
                <CiLocationOn />
                </span>
                {/* <div className="foot-icon-desc"> */}
                <Link>F3, Sai Shraddha Society, Kondhwa, Pune India</Link>
                {/* </div> */}
              </li>
              <li>
                <span className="foot-icon">
                <FiPhoneCall />
                </span>
                {/* <div className="foot-icon-desc"> */}
                <Link>(+91) 20-67622438</Link>
                {/* </div> */}
              </li>
              <li>
                <span className="foot-icon">
                <MdOutlineMailOutline />
                </span>
                {/* <div className="foot-icon-desc"> */}
                <Link to="mailto:support@econsignsoft.com">support@econsignsoft.com</Link>
                {/* </div> */}
              </li>
              <li>
                <span className="foot-icon">
                <CiClock2 />
                </span>
                {/* <div className="foot-icon-desc"> */}
                <Link>Opening Hours: 09:30 - 18:30</Link>
                {/* </div> */}
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="footer-section-4">
          <div className="footer-head">
            <label htmlFor="">News Letter</label>
          </div>
          <div className="footer-para">
            <p>We denounce with righteous and in and dislike men who are so beguiled and demo realized.</p>
            <div className="footer-form-input">
              <input type="text" placeholder="Your Email Address" />
              <div className="icon">
                
              </div>
            </div>
         
          </div>
        </div> */}
      </div>
    </>
  );
}

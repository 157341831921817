import React, { useState } from "react";
import aboutHome from "../../assets/images/about/about-home.png";
import ourWorking1 from "../../assets/images/process/1.png";
import ourWorking2 from "../../assets/images/process/2.png";
import ourWorking3 from "../../assets/images/process/3.png";
import ourWorking4 from "../../assets/images/process/4.png";
import videoImage from "../../assets/images/video/video.jpg";
import { useNavigate } from "react-router-dom";


export default function About() {
  const navigate = useNavigate();

  const [ourWorkingList, setOurWorkingList] = useState([
    {
      id: 1,
      img: ourWorking1,
      name: "Discovery",
    },
    {
      id: 2,
      img: ourWorking2,
      name: "Planning",
    },
    {
      id: 2,
      img: ourWorking3,
      name: "Execute",
    },
    {
      id: 3,
      img: ourWorking4,
      name: "Delievr",
    },
  ]);

  const steps = [
    { 
      id: 1, 
      image: ourWorking1, 
      title: "1. Discussion & Brainstorming", 
      description: "At EconSign Soft, we emphasize the importance of thorough discussions and brainstorming sessions to ensure the success of every project. Our brainstorming process is deeply rooted in software research, where we analyze the latest trends, tools, and technologies to identify the most effective solutions. During these sessions, our team collaborates to explore innovative ideas, evaluate feasibility, and align the proposed solutions with our clients' business objectives. By leveraging in-depth research and collective expertise, we aim to develop strategies that address challenges effectively, enhance user experience, and deliver cutting-edge software solutions tailored to meet unique requirements."
    },
    { 
      id: 2, 
      image: ourWorking2, 
      title: "2. Planning & Prototyping", 
      description: "At EconSign Soft, meticulous planning and prototyping form the foundation of our development process. During the planning phase, we outline clear project goals, define timelines, allocate resources, and establish a roadmap to ensure seamless execution. This stage is supported by extensive research and analysis to ensure every detail aligns with our clients' vision and business objectives."
    },
    { 
      id: 3, 
      image: ourWorking3, 
      title: "3. Execution", 
      description: "The execution phase at EconSign Soft is where plans and prototypes come to life. Our team of skilled developers and engineers leverages cutting-edge tools, technologies, and best practices to build robust and scalable software solutions. We follow a structured and agile development methodology to ensure flexibility, transparency, and timely delivery of each project milestone."
    },
    { 
      id: 4, 
      image: ourWorking4, 
      title: "4. Delivery & Deployment", 
      description: "At EconSign Soft, the Delivery & Deployment phase is the culmination of our development journey, where the final product is prepared for launch and handed over to the client. Before delivery, the software undergoes rigorous testing, including performance, security, and usability assessments, to ensure it meets the highest standards of quality and reliability."
    },
  ];
    return (
    <>
      <div className="about">
        <div className="about-heading">
          <h1 htmlFor="">About</h1>
          <label htmlFor=""><span onClick={() => navigate("/")} style={{ cursor: "pointer", }}>
            Home
          </span>{" "} / About</label>
        </div>

        <div className="about-us">
          <div className="about-us-icon">
            <img src={aboutHome} alt="" />
          </div>
          <div className="about-us-description">
            <a htmlFor="" className="small-heading">
              about us
            </a>
            {/* <h1 className="big-heading">
              We Are Increasing Business
              <br /> Success With Technology
            </h1> */}
            <p className="thick-paragraph">
              <strong style={{ fontSize: "30px" }}>EconSign Soft</strong> is a renowned software development company dedicated to delivering
              end-to-end IT solutions to businesses across the globe. With over a decade of expertise, we
              have been a trusted partner for organizations striving to achieve digital excellence.
              Our approach combines innovation, quality, and reliability to create tailored digital solutions that
              address the unique challenges of modern businesses. By leveraging next-generation
              technologies, we empower companies to enhance their operational efficiency, drive growth, and
              stay ahead in the competitive market.
              At EconSign Soft, we believe in fostering strong partnerships and delivering value-driven
              results. Our team of experienced professionals is committed to transforming your vision into
              reality through cutting-edge technology and creative problem-solving.

            </p>
            {/* <p className="thin-paragraph">
              EconSign Soft is a global information technology and consulting
              company have a well-established presence in India. We ensure the
              highest levels of certainty and satisfaction through a deep-set
              commitment to clients with our technology expertise, comprehensive
              portfolio of services and vertically aligned business model. We
              are committed to providing innovative web and mobile application
              development services to clients that not only create tremendous
              value, but provide an impressive return on investment.
            </p> */}
            <button className="learn-more-btn" onClick={() => navigate("/contact-us")}>Learn-More</button>
          </div>
        </div>
        {/* <div className="about-our-working">
          <div className="container">
            <div className="our-working-title">
              <div className="title">
                <a htmlFor="" className="small-heading">
                  Process
                </a>
              </div>
              <h2>Our Working Process</h2>
            </div>
            <div className="our-working-content">
              {ourWorkingList.map(({ id, name, img }, i) => (
                <>
                  <div key={id} className="our-working-content-container">
                    <div className="process-img">
                      <img src={img} alt="" />
                    </div>
                    <div className="process-text">
                      <h3 className="title">{name}</h3>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div> */}

        <div className="steps-container">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`step-item ${index % 2 === 0 ? "left" : "right"}`}
            >
              <div className="step-content">
                <img src={step.image} alt={step.title} className="step-image" />
              </div>
              <div className="step-sub-content">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="steps-container-mobile">
          {steps.map((step, index) => (
            <div
              key={step.id}
              className={`step-item ${index % 2 === 0 ? "left" : "right"}`}
            >
              <div className="step-content">
                <img src={step.image} alt={step.title} className="step-image" />
                <h3>{step.title}</h3>
              <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
        {/* ************************** Request A Free Consultation ***************************** */}
        <div className="request-consultation">
          <div className="container">
            <div className="request-title">
              <div className="title">
                <a htmlFor="" className="small-heading">
                  Contact
                </a>
              </div>
              <h2>
                Request A Free <br /> Consultation
              </h2>
            </div>
          </div>
          <div className="request-container">
            <div className="request-box">
              <div className="video-box">
                <img src={videoImage} alt="" />
                {/* <div className="playbtn">
                  <div className="playtri"></div>
                </div> */}

              </div>
              <div className="content-box">
                <div className="form-container">
                  <div className="form-input-container">
                    <input type="text" placeholder="Name" />
                  </div>
                  <div className="form-input-container">
                    <input type="text" placeholder="Email-Id" />
                  </div>
                  <div className="form-input-container">
                    <input type="text" placeholder="Phone Number" />
                  </div>
                  <div className="form-input-container">
                    <input type="text" placeholder="Your Website" />
                  </div>
                  <div className="form-textarea-container">
                    <textarea
                      name=""
                      id=""
                      placeholder="Your Message Here"
                      rows={5}
                    ></textarea>
                  </div>
                  <div className="submit-btn">
                    <button>Submit Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
        <div className="animation">

          <div className="container"></div>
          <div className="circle"></div>
        </div> */}
      </div>
    </>
  );
}

import Footer from "component/Common/Footer";
import Header from "component/Common/Header";
import About from "page/About/About";
import Contact from "page/Contact/Contact";
import Home from "page/Home/Home";
import AppDevelopment from "page/Services/AppDevelopment";
import FullStackDevelopment from "page/Services/FullStackDevelopment";
import OurServices from "page/Services/OurServices";
import SoftwareDevelopment from "page/Services/SoftwareDevelopment";
import UIUXDesign from "page/Services/UIUXDesign";
import WebDevelopment from "page/Services/WebDevelopment";
import { Outlet, Route, Routes } from "react-router-dom";

export default function Routing() {
  // Wrapper for Header and Outlet
  const Heading = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer/>
      </>
    );
  };

  return (
    <Routes>
      {/* Wrap all child routes with the Heading layout */}
      <Route element={<Heading />}>
        <Route path="/" element={<Home />} />
        <Route path="/about"  element={<About/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/contact-us"  element={<Contact/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/services/web-development"  element={<WebDevelopment/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/services/software-development"  element={<SoftwareDevelopment/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/services/full-stack-development"  element={<FullStackDevelopment/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/services/app-development"  element={<AppDevelopment/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/services/ui-ux-designs"  element={<UIUXDesign/>}/> {/* Replace with actual ContactUs component */}
        <Route path="/services/our-services"  element={<OurServices/>}/> {/* Replace with actual ContactUs component */}
      </Route>
    </Routes>
  );
}

import React, { useState } from "react";
import "./BusinessSection.scss"; // Import the SCSS file

import Technology from "../../assets/images/technology.gif";
import Image2 from "../../assets/images/about/main-home/about-part-2.png";
import Image3 from "../../assets/images/about/main-home/about-part-3.png";
import { useNavigate } from "react-router-dom";


function BusinessSection() {
  const [animate, setAnimate] = useState(false);
  const navigate = useNavigate();


  const handleMouseEnter = () => setAnimate(true);

  const skills = [
    { name: "Full Stack Development", percent: 92 },
    { name: "Software Development", percent: 90 },
    { name: "App Development", percent: 85 },
    { name: "Web Development", percent: 95 },
  ];

  return (
    <div className="business-section">
      <div className="images-container">
          <img src={Technology} alt="Image 1" className="image" />
      </div>

      <div
        className="text-container"
        onMouseEnter={handleMouseEnter}
      >
        <h2>We Are Increasing Business Success With Technology</h2>
        <p>
          Over 10+ years working in IT services developing software applications
          and mobile apps for clients all over the world.
        </p>

        <div className="skills">
          {skills.map((skill, index) => (
            <div className="skill" key={index}>
              <a style={{ fontWeight: "500" }}>{skill.name}</a>
              <span className="progress-percent">{`${skill.percent}%`}</span>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{
                    width: animate ? `${skill.percent}%` : "0%",
                    transition: animate ? "width 2s ease-in-out" : "none",
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        <br />
        <button className="learn-more-btn" onClick={() => navigate("/contact-us")}>Learn-More</button>
      </div>
    </div>
  );
}

export default BusinessSection;

import React from 'react'
import Banner from '../../component/HomePage/Banner'
import BusinessSection from '../../component/HomePage/BusinessSection'
import Services from '../../component/HomePage/Services'
import LetsTalk from '../../component/HomePage/LetsTalk'

export default function HomePage() {
  return (
    <div>
      <Banner/>
      <BusinessSection/>
      <Services/>
      <LetsTalk/>
    </div>
  )
}

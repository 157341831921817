import React, { useState } from "react";
import UIUXDesignImage from "../../assets/images/about/about-us/1.jpg";
import img1 from "../../assets/images/services/style7/1.png";
import img2 from "../../assets/images/services/style7/2.png";
import img3 from "../../assets/images/services/style7/3.png";
import choose from "../../assets/images/choose/3.png";
import "./UIUXDesign.scss"; // Import the SCSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";


export default function UIUXDesign() {
    const [animate, setAnimate] = useState(false);
    const navigate = useNavigate();


    const handleMouseEnter = () => setAnimate(true);

    const skills = [
        { name: "Full Stack Development", percent: 92 },
        { name: "Software Development", percent: 90 },
        { name: "App Development", percent: 85 },
        { name: "Web Development", percent: 95 },
    ];

    return (
        <>
            <div className="UIUXDesign">
                <div className="UIUXDesign-heading">
                    <h1>UI/UX Development</h1>
                    <label><span onClick={() => navigate("/")} style={{ cursor: "pointer", }}>
                            Home
                        </span>{" "} /
                        <span onClick={() => navigate("/services/our-services")} style={{ cursor: "pointer", paddingLeft: "5px" }}>
                            Services
                        </span>{" "}/ UI/UX Development</label>
                </div>

                {/* First Section: Left Text and Right Image */}
                <div className="UIUXDesign-section">
                    <div className="UIUXDesign-text">
                        <p className="subtext">UI/UX Development</p>
                        <div className="animation">

                            <div className="container"></div>
                            <div className="circle"></div>
                        </div>
                        <p className="subtext2">UI/UX Development</p>
                        <p className="subtext3">We at Econsign Soft work in dedicated project teams, with creative, intuitive, immersive UI UX designs.</p>
                        <p className="subtext4">
                        At Econsign Soft, we help our clients to create brilliant designs and superior user experiences through UX research, behavioral analytics and market insights. We explore innovative digital experience with a blend of technology, creativity and personalization in UI and UX development services.                     </p>
                        <ul className="listing-style pt-20">
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Research</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Storyboarding</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Hybrid Model</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Wireframes</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Visual Design</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Graphic Design</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>Branding</span>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                <span>User Testing</span>
                            </li>
                        </ul> <br/>
                        <button onClick={() => navigate("/contact-us")}>Contact Us</button>
                    </div>
                    <div className="UIUXDesign-image">
                        <img src={UIUXDesignImage} alt="Software Development" />
                    </div>
                </div>

                {/* Second Section: Three Cards */}
                <div className="UIUXDesign-cards">
                    <div className="card">
                        <img src={img1} alt="Expert People" />
                        <div className="card-content">
                            <h3>Expert People</h3>
                            <p>We have amassed significant expertise across a wide range of industries.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src={img2} alt="Fast Growing Process" />
                        <div className="card-content">
                            <h3>Fast Growing Process</h3>
                            <p>With our expertise and dedicated approach we help in growing our client business effectively.</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src={img3} alt="Creative Ideas" />
                        <div className="card-content">
                            <h3>Creative Ideas</h3>
                            <p>We have innovative and creative business ideas which help our clients to stay one step ahead.</p>
                        </div>
                    </div>
                </div><br /><br /><br /><br />
                <div className="business-section">
                    <img alt="Image 1" src={choose} className="image" />

                    <div
                        className="text-container"
                        onMouseEnter={handleMouseEnter}
                    >
                        <h6>Why Choose Us</h6>
                        <h2 style={{ fontSize: "36px" }}>We Are Increasing Business Success With Technology</h2>
                        <p style={{ fontSize: "18px" }}>
                            Over 8 years working in IT services developing software applications
                            and mobile apps for clients all over the world.
                        </p>

                        <div className="skills">
                            {skills.map((skill, index) => (
                                <div className="skill" key={index}>
                                    {/* <a style={{ fontWeight: "500" }}>{skill.name}</a> */}
                                    <span className="progress-percent">{`${skill.percent}%`}</span>
                                    <div className="progress-bar">
                                        <div
                                            className="progress"
                                            style={{
                                                width: animate ? `${skill.percent}%` : "0%",
                                                transition: animate ? "width 2s ease-in-out" : "none",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br />
                        <button className="learn-more-btn" onClick={() => navigate("/contact-us")}>Learn-More</button>
                    </div>
                </div>
            </div>
        </>
    );
}

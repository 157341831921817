import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




export default function Contact() {
  const navigate = useNavigate();

  return (
    <>
      <div className="about">
        <div className="contact-heading">
          <h1 style={{color:"#000"}} htmlFor="">Contact</h1>
          <label htmlFor="" style={{color:"#000"}}> <span onClick={() => navigate("/")} style={{ cursor: "pointer", }}>
                            Home
                        </span>{" "} / Contact</label>
        </div>
        <div className="fill-the-form-below">
          <div className="container">
            <div className="left-container">
              <div className="title-container">
                <label htmlFor="">let's talk</label>
                <h2 className="title">Speak with expert engineers</h2>
              </div>
              <div className="address-box">
                <div className="address-icon"><FontAwesomeIcon icon={faHome} className="icon" /></div>
                <div className="address-text">
                  <span className="label">Email :</span>
                  <Link to={"support@econsignsoft.com"}>
                    support@econsignsoft.com
                  </Link>
                </div>
              </div>
              <div className="address-box">
                <div className="address-icon"><FontAwesomeIcon icon={faPhone} className="icon" /></div>
                <div className="address-text">
                  <span className="label">Phone :</span>
                  <Link to={"support@econsignsoft.com"}>+91 20-67622438</Link>
                </div>
              </div>
              <div className="address-box">
                <div className="address-icon"><FontAwesomeIcon icon={faLocationPin} className="icon" /></div>
                <div className="address-text">
                  <span className="label">Address :</span>
                  <div className="desc" to={"support@econsignsoft.com"}>
                    Kondhwa, Pune-411060 India
                  </div>
                </div>
              </div>
            </div>
            <div className="right-container">
              <div className="title-container2">
                <label className="contact-sub-text">Get In Touch</label>
                <br />
                <br />
                <h2>Fill The Form Below</h2>
              </div>
              <div className="form-container">
                <div className="form-input-container">
                  <input type="text" placeholder="Name" />
                </div>
                <div className="form-input-container">
                  <input type="text" placeholder="Email-Id" />
                </div>
                <div className="form-input-container">
                  <input type="text" placeholder="Phone Number" />
                </div>
                <div className="form-input-container">
                  <input type="text" placeholder="Your Website" />
                </div>
                <div className="form-textarea-container">
                  <textarea
                    name=""
                    id=""
                    placeholder="Your Message Here"
                    rows={5}
                  ></textarea>
                </div>
                <div className="submit-btn">
                  <button>Submit Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="live-location-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15139.171147451823!2d73.8993912!3d18.4477151!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebd31be5f9c7%3A0xb407ae10706907e7!2sEconsign%20Soft%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674721113230!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
      </div>
    </>
  );
}

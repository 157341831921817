import React from 'react';
import './Services.scss'; // Import the SCSS file
import Softwaredevelopment from '../../assets/images/services/main-home/icons/1.png';
import Webdevelopment from '../../assets/images/services/main-home/icons/2.png';
import Fullstackdevelopment from '../../assets/images/services/main-home/icons/3.png';
import Appdevelopment from '../../assets/images/services/main-home/icons/4.png';
import Uiuxdesign from '../../assets/images/services/main-home/icons/5.png';

function Services() {
    return (
        <div>
            <section className="services-section">
                <h2 className="services-heading">Services</h2>
                <p className="services-subheading">We Are Offering All Kinds of IT Solutions Services</p><br/>

                <div className="services-cards-wrapper">
                    <div className="services-cards">
                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Softwaredevelopment} alt="Software Development" />
                            </div>
                            <h3 className="service-card-title">Software Development</h3>
                            <p className="service-card-description">
                            From conceptualization to deployment, we create robust and scalable software solutions
                            tailored to meet specific business requirements.                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Webdevelopment} alt="Web Development" />
                            </div>
                            <h3 className="service-card-title">Web Development</h3>
                            <p className="service-card-description">
                            Our expertise in web technologies allows us to build dynamic, user-friendly, and
                            responsive websites that enhance your online presence                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Fullstackdevelopment} alt="Full Stack Development" />
                            </div>
                            <h3 className="service-card-title">Full Stack Development</h3>
                            <p className="service-card-description">
                            We provide comprehensive full-stack development services, encompassing both
                            front-end and back-end technologies, to deliver seamless and efficient applications.                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Appdevelopment} alt="App Development" />
                            </div>
                            <h3 className="service-card-title">App Development</h3>
                            <p className="service-card-description">
                            We specialize in developing feature-rich mobile applications for iOS, Android, and
                            cross-platform environments to help businesses connect with their customers on the go                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Uiuxdesign} alt="UI/UX Design" />
                            </div>
                            <h3 className="service-card-title">UI/UX Design</h3>
                            <p className="service-card-description">
                            Our design team focuses on creating intuitive, aesthetically pleasing, and user-centric
                            interfaces to deliver an exceptional user experience.                            </p>
                        </div>

                        {/* Clone the cards to create a seamless looping effect */}
                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Softwaredevelopment} alt="Software Development" />
                            </div>
                            <h3 className="service-card-title">Software Development</h3>
                            <p className="service-card-description">
                                Econsign Soft has proven expertise in building reliable and scalable software solutions that bring new value to your business.
                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Webdevelopment} alt="Web Development" />
                            </div>
                            <h3 className="service-card-title">Web Development</h3>
                            <p className="service-card-description">
                                We provide top-quality website development services for startups, enterprises & entrepreneurs.
                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Fullstackdevelopment} alt="Full Stack Development" />
                            </div>
                            <h3 className="service-card-title">Full Stack Development</h3>
                            <p className="service-card-description">
                                Leading full-stack web development firm that develops front-end and back-end of web applications as per market standard.
                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Appdevelopment} alt="App Development" />
                            </div>
                            <h3 className="service-card-title">App Development</h3>
                            <p className="service-card-description">
                                We build a mobile app tailored to your niche under your budget that improves user experience & increases brand visibility.
                            </p>
                        </div>

                        <div className="service-card">
                            <div className="service-card-icon">
                                <img src={Uiuxdesign} alt="UI/UX Design" />
                            </div>
                            <h3 className="service-card-title">UI/UX Design</h3>
                            <p className="service-card-description">
                                We at Econsign Soft work dedicatedly to provide creative, intuitive, user research, UX design, UI, and engineering.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;

import React, { useEffect, useState } from "react";
import logo from "assets/images/eLogo.png";
import { TbPhoneCall } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  FaCode,
  FaLaptopCode,
  FaMobileAlt,
  FaPalette,
  FaCogs,
} from "react-icons/fa";
import { color } from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  console.log(isMenuOpen)
 
  return (
    <>
      <div className="header">
        <div className="logo">
          <Link to={"/"}>
            <img src={logo} style={{ width: "50%" }} alt="" />
          </Link>
        </div>
        <div className="header-info">
          <div className="header-info-container1">
            <div className="header-info-icon">
              <SlLocationPin />
            </div>
            <div className="header-info-desc">
              <div className="header-desc-head">
                <label htmlFor="">Address</label>
              </div>
              <div className="header-desc-paragraph">
                <p>Kondhwa Pune</p>
              </div>
            </div>
          </div>

          <div className="header-info-container">
            <div className="header-info-icon">
              <MdOutlineMailOutline />
            </div>
            <div className="header-info-desc">
              <div className="header-desc-head">
                <label htmlFor="">E-mail</label>
              </div>
              <div className="header-desc-paragraph">
                <p>
                  <Link
                    style={{ color: "#000" }}
                    to="mailto:support@econsignsoft.com"
                  >
                    support@econsignsoft.com
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="header-info-container">
            <div className="header-info-icon">
              <TbPhoneCall />
            </div>
            <div className="header-info-desc">
              <div className="header-desc-head">
                <label htmlFor="">Phone</label>
              </div>
              <div className="header-desc-paragraph">
                <p>+91 20-67622438</p>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-btn" onClick={()=>setIsMenuOpen(!isMenuOpen)}>
          {!isMenuOpen ? (
            <GiHamburgerMenu className="icon" />
          ) : (
            <IoCloseSharp className="icon" />
          )}
        </div>
      </div>

      <nav className="nav1">
        <ul className="list">
          <li>
            <Link to={"/"} >Home</Link>
          </li>
          <li>
            <Link to={"/about"} >About</Link>
          </li>
          <li>
            <Link>Services+ </Link>

            <ul className="sub-list">
              <li >
                <Link to={"/services/software-development"} >
                  <FaCogs className="sub-list-icon" /> Software Development
                </Link>
              </li>
              <li>
                <Link to={"/services/web-development"} >
                  <FaLaptopCode className="sub-list-icon" /> Web Development
                </Link>
              </li>
              <li>
                <Link to={"/services/full-stack-development"}>
                  <FaCode className="sub-list-icon" /> Full Stack Development
                </Link>
              </li>
              <li>
                <Link to={"/services/app-development"} >
                  <FaMobileAlt className="sub-list-icon" /> App Development
                </Link>
              </li>
              <li>
                <Link to={"/services/ui-ux-designs"} >
                  <FaPalette className="sub-list-icon" /> UI/UX Design
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"/contact-us"} >&nbsp;Contact</Link>
          </li>
        </ul>

        <ul className="icon-list">
          <li>
            <Link to={"https://www.facebook.com/econsignsoft"}>
              <FaFacebookF />
            </Link>
          </li>
          <li>
            <Link to={"https://x.com/econsignsoft"}>
              <RiTwitterXLine />
            </Link>
          </li>
          <li>
            <Link to={"https://www.linkedin.com/company/econsignsoft/"}>
              <FaLinkedinIn />
            </Link>
          </li>
          <li>
            <Link to={"https://www.instagram.com/_econsignsoft_/"}>
              <FaInstagram />
            </Link>
          </li>
        </ul>
      </nav>

      <nav className="nav2" style={!isMenuOpen  ?{display:"none"}:{display:"block"}}>
        <ul className="list">
          <li>
            <Link to={"/"} onClick={()=>setIsMenuOpen(false)}>Home</Link>
          </li>
          <li>
            <Link to={"/about"} onClick={()=>setIsMenuOpen(false)}>About</Link>
          </li>
          <li>
            <Link>Services +</Link>

            <ul className="sub-list">
              <li >
                <Link to={"/services/software-development"} onClick={()=>setIsMenuOpen(false)}>
                  <FaCogs className="sub-list-icon" /> Software Development
                </Link>
              </li>
              <li>
                <Link to={"/services/web-development"} onClick={()=>setIsMenuOpen(false)}>
                  <FaLaptopCode className="sub-list-icon" /> Web Development
                </Link>
              </li>
              <li>
                <Link to={"/services/full-stack-development"} onClick={()=>setIsMenuOpen(false)}>
                  <FaCode className="sub-list-icon" /> Full Stack Development
                </Link>
              </li>
              <li>
                <Link to={"/services/app-development"} onClick={()=>setIsMenuOpen(false)}>
                  <FaMobileAlt className="sub-list-icon" /> App Development
                </Link>
              </li>
              <li>
                <Link to={"/services/ui-ux-designs"} onClick={()=>setIsMenuOpen(false)}>
                  <FaPalette className="sub-list-icon" /> UI/UX Design
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"/contact-us"} onClick={()=>setIsMenuOpen(false)}>Contact</Link>
          </li>
        </ul>

        <ul className="icon-list">
          <li>
            <Link to={"https://www.facebook.com/econsignsoft"}>
              <FaFacebookF />
            </Link>
          </li>
          <li>
            <Link to={"https://x.com/econsignsoft"}>
              <RiTwitterXLine />
            </Link>
          </li>
          <li>
            <Link to={"https://www.linkedin.com/company/econsignsoft/"}>
              <FaLinkedinIn />
            </Link>
          </li>
          <li>
            <Link to={"https://www.instagram.com/_econsignsoft_/"}>
              <FaInstagram />
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

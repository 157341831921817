import React from "react";
import WebDevelopmentImage from "../../assets/images/services/single/1.jpg";
import "./WebDevelopment.scss"; // Import the SCSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";


export default function WebDevelopment() {
  const navigate = useNavigate();
  return (
    <>
      <div className="WebDevelopment">
        <div className="WebDevelopment-heading">
          <h1>Web Development</h1>
          <label><span onClick={() => navigate("/")} style={{ cursor: "pointer", }}>
                            Home
                        </span>{" "} /
                        <span onClick={() => navigate("/services/our-services")} style={{ cursor: "pointer", paddingLeft: "5px" }}>
                            Services
                        </span>{" "}/ Web Development</label>
        </div>

        <div className="WebDevelopment-us">
          <div className="WebDevelopment-us-icon">
            <img src={WebDevelopmentImage} alt="Image 1" className="image" />
            <div className="contactcontent">
              <h3>Responsive Pixel Perfect Design</h3>
              <p>Econsign soft craft splendid and deliver experiences that shines. We help startups & businesses with creative, intuitive, immersive UI/UX designs that seamlessly guide users in performing the desired actions flawlessly. We help brands create brilliant designs and superior user experiences through UX research, behavioral analytics and market insights.</p>

              <h4>24/7 Smart Support</h4>
              <p>We at Econsign Soft are always available for quick smart support 24/7.</p>
            </div>
          </div>

          <div className="WebDevelopment-us-description">
            <div className="button-list">
              <button className="card-button" onClick={() => navigate("/services/software-development")}>
                Software Development
                <FontAwesomeIcon icon={faChevronRight} className="icon-right" />
              </button>
              <button className="card-button" onClick={() => navigate("/services/web-development")}>
                Web Development
                <FontAwesomeIcon icon={faChevronRight} className="icon-right" />
              </button>
              <button className="card-button" onClick={() => navigate("/services/full-stack-development")}>
                Full Stack Development
                <FontAwesomeIcon icon={faChevronRight} className="icon-right" />
              </button>
              <button className="card-button" onClick={() => navigate("/services/app-development")}>
                App Development
                <FontAwesomeIcon icon={faChevronRight} className="icon-right" />
              </button>
              <button className="card-button" onClick={() => navigate("/services/ui-ux-designs")}>
                UI/UX Design
                <FontAwesomeIcon icon={faChevronRight} className="icon-right" />
              </button>
            </div>

            {/* New Card Section */}
            <div className="contact-card">
              <h3>Have any Questions?<br />Call us Today!</h3>
              <p>(+91) 20-67622438</p>
            </div>

          </div>
        </div>
      </div>
      <div className="planproject">
        <p>Plan to Start a Project</p>
        <div className="text-container">
          <h3>Our Experts Ready to Help You</h3>
          <button>Get In Touch</button>
        </div>
      </div>

    </>
  );
}
